<template>
  <div class="suggestList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>会议需求</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>建议类型:</span>
      <el-select
        @clear="onClear(1)"
        v-model="list.meetingType"
        clearable
        placeholder="请选择建议类型"
      >
        <el-option
          v-for="item in meetingType"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>手机号码:</span>
      <el-input
        v-model="list.phone"
        placeholder="请输入手机号码"
      ></el-input>
      <el-button type="primary" @click="getOptionList()">查找</el-button>
    </div>
    <el-table border :data="tableDate" style="width: 100%">
      <el-table-column prop="addTime" label="时间" width="width">
      </el-table-column>
      <el-table-column prop="backType" label="类型" width="width">
      </el-table-column>
      <el-table-column prop="linkMan" label="联系人" width="width">
      </el-table-column>
      <el-table-column prop="phone" label="手机号" width="width">
      </el-table-column>
      <el-table-column prop="people" label="人数" width="width">
      </el-table-column>
      <el-table-column  label="是否处理" width="width">
        <template  slot-scope="{ row }"> 
            <span v-if="row.status == 0">未处理</span>
            <span v-else>已处理</span>
        </template>
      </el-table-column>
      <el-table-column prop="need" label="需求" width="width">
      </el-table-column>
      <el-table-column prop="fee" label="预算" width="width"> </el-table-column>
      <el-table-column prop="" label="操作" width="width">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="onupdate(row)"
            >添加备注</el-button
          >
          <el-button type="primary" size="mini" @click="onUpdateNeed(row)"
            >处理</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      @close="onColse"
      title="添加备注"
      :visible.sync="dialogVisible"
      width="width"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="	备注">
          <el-input
            type="textarea"
            rows="6"
            v-model="list2.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { needList, needUpdate, needAddRemark } from "../../api/feeback";
export default {
  name: "suggestList",
  data() {
    return {
      dialogVisible: false,
      meetingType: [
        { value: 1, label: "年会" },
        { value: 2, label: "培训会" },
        { value: 3, label: "拓展会" },
        { value: 4, label: "工作会" },
        { value: 5, label: "研讨会" },
        { value: 6, label: "同学会" },
        { value: 7, label: "交流会" },
        { value: 8, label: "休闲会" },
      ],
      DisposeOptions: [
        { value: 0, label: "已处理" },
        { value: 1, label: "未处理" },
      ],
      list: {
        phone: "",
        linkMan: null,
        status: null,
        currentPage: 1,
        pageSize: 5,
      },
      list2:{
          id:'',
          remark:''
      },
      tableDate: [],
      pagination: {},
      uptateList: {
        opinionId: null,
        disposeCont: "",
      },
    };
  },
  created() {
    this.getOptionList();
  },
  methods: {
      onUpdateNeed(row){
             this.$confirm('是否操作这条数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
            const {data} =  await needUpdate({id:row.id});
           if(data.code == 0){
               this.$message({message:'处理成功！',type:'success'})
               this.getOptionList();
           }else{
               this.$message({message:data.msg,type:'error'})
           }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      },
           
    onClear(type) {
      if (type == 1) {
        this.list.opinionType = null;
      } else if (type == 2) {
        this.list.isDispose = null;
      }
    },
    onColse() {
      this.uptateList.opinionId = null;
      this.uptateList.disposeCont = "";
    },
    async onUpdateOk(row) {
        console.log(row,'row')
      if (this.list2.remark == "") {
        return this.$message.error("请填写处理内容");
      }
      const { data } = await needAddRemark(this.list2);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        this.getOptionList();
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
        this.dialogVisible = false;
      }
    },
    onupdate(row) {
        console.log(row,'row')
        this.list2.id = row.id
    //   this.uptateList.opinionId = row.opinionId;
      this.dialogVisible = true;
    },
    async getOptionList() {
      const { data } = await needList(this.list);
      if (data.code == 0) {
        this.tableDate = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message.error(data.msg);
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getOptionList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getOptionList();
    },
  },
};
</script>
<style lang="less" scoped>
.suggestList {
  .search {
    margin-top: 50px;
    span {
      margin: 0 10px 0 40px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin: 0 20px;
    }
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      .tbody {
        .el-button {
        }
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>