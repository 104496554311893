import request from '../utils/request'
import baseUrl from './baseUrl'

export const needList = (data) => request({
  url: baseUrl + '/meeting/selectForBack',
  method: 'POST',
  data
})
export const needUpdate = (data) => request({
  url: baseUrl + '/meeting/modify',
  method: 'POST',
  data
})
export const needAddRemark = (data) => request({
  url: baseUrl + '/meeting/modifyRemark',
  method: 'POST',
  data
})